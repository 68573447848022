import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import es from "date-fns/locale/es";
import * as moment from 'moment';

export default function PromocionDataForm({ disabled, promotions, inputs, setInput,setError, errors, handleInputChange, handleSelectChange, isLoadingPromotions }) {
    const [maxDateCupon, setMaxDateCupon] = useState([''])

    // Efecto a realizar cuando cambia el valor de la prop disabled
    useEffect(() => {
        if (!disabled) {
            // getData();
        }
    }, [disabled])

    useEffect(() => {
        (async () => {

            if (inputs.promotion && inputs.promotion.value) {
                const promotionObject = promotions.find(p => p.IdPromotion === inputs.promotion.value);
                setInput('namePromotion', promotionObject.Name);
                setError('namePromotion',false);
                setMaxDateCupon(promotionObject.EndDate);
            }
            
        })();
    }, [inputs.promotion])

    const validateMinCharecterNamePromotion = async (e) =>{
        if(e.target.value && e.target.value.length>0 && e.target.value.length<3){
            setError(e.target.name,true);
        }else if(e.target.value.length>=3){
            setError(e.target.name,false);
        }
    }

    // const getData = async () => {
    //     if(inputs.category && inputs.category.value){
    //         let res = await PromocionAPI.getTags(inputs.category.value);
    //         if(res.data){
    //             setTags(res.data);
    //         }
    //     }
    // }

    return (
        <div className="columns is-multiline">

            <div className="column is-6">
                <label className="label">Promoción</label>
                <div className={`control`}>
                    <Select
                        placeholder={"Seleccionar..."}
                        name={"promotion"}
                        value={inputs.promotion}
                        isClearable
                        onChange={(value, event) => handleSelectChange(value, event, true)}
                        noOptionsMessage={() => "No hay opciones"}
                        isLoading={isLoadingPromotions}
                        options={promotions.map(p => { return { value: p.IdPromotion, label: p.Name } })}
                    />
                </div>
                {errors.promotion && <p className="help title has-text-left is-6 is-danger">Promoción requerida</p>}
            </div>
            <div className="column is-6">
                <label className="label">Nombre de la promoción</label>
                <div className={`control`}>
                    <input
                        type="text"
                        name="namePromotion"
                        className={`input ${errors.namePromotion && "is-danger"}`}
                        maxLength={50}
                        minLength={3}
                        placeholder={`Nombre de la promoción`}
                        value={inputs.namePromotion}
                        onChange={(e) => {handleInputChange(e, true);validateMinCharecterNamePromotion(e);}}
                        required
                    />
                </div>
                {errors.namePromotion && <p className="help title has-text-left is-6 is-danger">{inputs.namePromotion && inputs.namePromotion.length>0 && inputs.namePromotion.length < 3?`El nombre debe de tener 3 o más caracteres`:`Nombre requerido`}</p>}
            </div>
            <div className="column is-6">
                <label className="label">Fecha inicio cupón</label>
                <div className="control has-text-centered">
                    <DatePicker
                        className={`input ${errors.startDateCupon && "is-danger"}`}
                        placeholderText="Seleccionar fecha de inicio..."
                        selected={inputs.startDateCupon}
                        disabled={!(inputs.promotion && inputs.promotion.value)}
                        minDate={moment().toDate()}
                        onChange={date => {
                            setInput('startDateCupon', date)
                        }}
                        maxDate={(inputs.promotion && inputs.endDateCupon)?inputs.endDateCupon : moment(maxDateCupon).toDate()}
                        onChangeRaw={(e) => e.preventDefault()}
                        dateFormat="dd/MMMM/yyyy"
                        locale={es}
                        required
                    />
                </div>
                {errors.startDateCupon && <p className="help title has-text-left is-6 is-danger">Fecha de inicio de cupón requerida</p>}
            </div>
            <div className="column is-6">
                <label className="label">Fecha final cupón</label>
                <div className="control has-text-centered">
                    <DatePicker
                        className={`input ${errors.endDateCupon && "is-danger"}`}
                        placeholderText="Seleccionar fecha de final..."
                        selected={inputs.endDateCupon}
                        minDate={inputs.startDateCupon? inputs.startDateCupon:moment().toDate()}
                        onChange={date => {
                            setInput('endDateCupon', date)
                        }}
                        disabled={!(inputs.startDateCupon)}
                        maxDate={inputs.promotion && moment(maxDateCupon).toDate()}
                        onChangeRaw={(e) => e.preventDefault()}
                        dateFormat="dd/MMMM/yyyy"
                        locale={es}
                        required
                    />
                </div>
                {errors.endDateCupon && <p className="help title has-text-left is-6 is-danger">Fecha final de cupón requerida</p>}
            </div>

            <div className="column is-6">
                        <label className="label">Descripción</label>
                        <div className={`control`}>

                            <textarea
                                name="descriptionApp"
                                className={`textarea ${errors.descriptionApp && "is-danger"}`}
                                rows="5"
                                maxLength={500}
                                placeholder={`Descripción`}
                                value={inputs.descriptionApp}
                                onChange={(e) => handleInputChange(e, true)}
                                required={inputs.showApp}
                            />
                        </div>
                        {errors.descriptionApp && <p className="help title has-text-left is-6 is-danger">Descripción requerida</p>}
                    </div>
                    <div className="column is-6">
                        <label className="label">Restricciones</label>
                        <div className={`control`}>
                            <textarea
                                name="restrictionsApp"
                                className={`textarea ${errors.restrictionsApp && "is-danger"}`}
                                rows="5"
                                maxLength={500}
                                placeholder={`Restricciones`}
                                value={inputs.restrictionsApp}
                                onChange={(e) => handleInputChange(e, true)}
                                required
                            />
                        </div>
                        {errors.restrictionsApp && <p className="help title has-text-left is-6 is-danger">Restricciones requerida</p>}
                    </div>
        </div>


    )
}