//Constantes a utilizar en la aplicación

//PRUEBAS
//export const url = "http://auth.vasopinguino.com";
//export const apiUrl = "http://sag.vasopinguino.com/api/v1";
//export const apiUrl = `http://localhost:58387/api/v1`;
//http://sagnotificaciones.vasopinguino.com/
//export const apiUrl = `http://198.168.20.13:7516/api/v1`;
//export const apiUrl = `http://sagnotificaciones.vasopinguino.com/api/v1`;
//export const caffenioAuthId = "4AA1B304-E37F-4D53-BFA5-68791FF7F6D9";

//export const url = "http://198.168.20.13:29001";
//export const apiUrl = "http://198.168.20.13:9000/api/v1";
//export const caffenioAuthId = "4AA1B304-E37F-4D53-BFA5-68791FF7F6D9";

// PREPRODUCTIVO
//export const url = "https://auth.caffenio.com";
// export const apiUrl = `http://localhost:58387/api/v1`;
//export const caffenioAuthId = "4AA1B304-E37F-4D53-BFA5-68791FF7F6D9";

//PRODUCTIVO
export const url = "https://auth.caffenio.com"
export const apiUrl = "https://sag.caffenio.com/api/v1";
export const caffenioAuthId = "4AA1B304-E37F-4D53-BFA5-68791FF7F6D9";