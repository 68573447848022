import React from 'react';
import './app-container.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'bulma-extensions/bulma-divider/dist/css/bulma-divider.min.css';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import posed, { PoseGroup } from 'react-pose';
import Menu from '../menu/Menu';
import Header from '../header/Header';
import Index from '../index';
import Recipes from '../Recetas/recipes/Recipes';
import RecipesImage from '../Recetas/recipesImage/RecipesImage';
import NewRecipe from '../Recetas/recipes/NewRecipe';
import Classifications from '../Recetas/classifications/Classifications';
import NewClassification from '../Recetas/classifications/NewClassification';
import NewFlavor from '../Recetas/flavors/NewFlavor';
import Flavors from '../Recetas/flavors/Flavors';
import NewGrain from '../Recetas/grains/NewGrain';
import Grains from '../Recetas/grains/Grains';
import NewMilk from '../Recetas/milks/NewMilk';
import Milks from '../Recetas/milks/Milks';
import NewPresentation from '../Recetas/presentations/NewPresentation';
import Presentations from '../Recetas/presentations/Presentations';
import NewInventoryItem from '../Recetas/InventoryItems/NewInventoryItem';
import InventoryItems from '../Recetas/InventoryItems/InventoryItems';
import RecipeTypes from '../Recetas/recipeTypes/RecipeTypes';
import NewRecipeType from '../Recetas//recipeTypes/NewRecipeType';
import NewUnit from '../Recetas/units/NewUnit';
import Units from '../Recetas/units/Units';
import NewInventoryType from '../InventoryType/NewInventoryType';
import InventoryType from '../InventoryType/InventoryTypes';
import Warehouse from '../warehouse/Warehouse';
import Remesas from '../remesas/Remesas';
import MenuDrives from '../menusDrive/MenusDrive';
import NewMenu from '../menusDrive/NewMenu';
import MenuDetail from '../menusDrive/MenuDetail';
import PricesDrive from '../pricesDrives/PricesDrive';
import NewPriceList from '../pricesDrives/NewPriceList';
import PriceDetail from '../pricesDrives/PricesDetail';
import PrivateRoute from '../util/privaterouter/PrivateRoute';
import SaldosRegalo from '../SaldoRegalo/SaldosRegalo';
import roles from '../../util/strings/roles';
import CaffenioDrive from '../caffenioDrive/caffenioDrive';
import NewCaffenio from '../caffenioDrive/NewCaffenio';
import NewRecipeMulti from '../Recetas/recipes/NewRecipeMulti';
import Toppings from '../Recetas/toppings/Toppings';
import Segmentaciones from '../Caffenio App/Segmentaciones/Segmentaciones';
import NuevaSegmentacion from '../Caffenio App/Segmentaciones/NuevaSegmentacion/NuevaSegmentacion';
import NuevaSegmentacionUsuario from '../Caffenio App/Segmentaciones/NuevaSegmentacion/NuevaSegmentacionUsuario';
import Notificaciones from '../Caffenio App/Notificaciones/Notificaciones';
import NuevaNotificacion from '../Caffenio App/Notificaciones/NuevaNotificacion/NuevaNotificacion';
import Agenda from '../Caffenio App/Agenda/Agenda';
import DetalleNotificacion from '../Caffenio App/Notificaciones/DetalleNotificacion/DetalleNotificacion';
import OrdenMenuApp from '../Caffenio App/Menu/Orden/OrdenMenuApp';
import EditarClasificacionApp from '../Caffenio App/Menu/Orden/EditarClasificacionApp/EditarClasificacionApp';
import DetalleClasificacionApp from '../Caffenio App/Menu/Orden/DetalleClasificacionApp/DetalleClasificacionApp';
import OrdenarMenu from '../Caffenio App/Menu/Orden/OrdernarMenu/OrdernarMenu';
import Restaurantes from '../ubereats/restaurantes';
import InfoOrderRestaurant from '../ubereats/InfoOrderRestaurant';
import RestaurantDetails from '../ubereats/RestaurantDetail';
import {AsignacionLiderSupervisor} from '../asignacionLiderSupervisor/AsignacionLiderSupervisor';
import {HistorialMovimientos} from '../historialMovimientos/HistorialMovimientos';
import CaffenioReporteTabla from "../reportes/caffenioReporteTabla";
import newFeatured from '../productoDestacado/newFeatured';
import {CatalogoPlazas} from '../CatalogoPlazas/CatalogoPlazas';
import {CatalogoSucursales} from '../CatalogoSucursales/CatalogoSucursales';
import {AdminUsuarios} from '../AdminUsuarios/AdminUsuarios';
import  PromosDrive  from '../Promos/Promos';
import { NuevaPromo } from '../Promos/NuevaPromo';
import {CatalogoImpuestos} from '../TasaImpuesto/TasaImpuesto';
import ReporteConciliacion from '../reportes/reporteConciliacion/reporteConciliacion';
import ReporteArqueo from '../reportes/reporteArqueo/reporteArqueo';
import ReporteRetiros from '../reportes/reporteRetiros/ReporteRetiros';
import ReporteCancelaciones from '../reportes/reporteCancelaciones/ReporteCancelaciones';
import Promociones from '../Promociones/Promociones';
import NuevaPromocion from '../Promociones/NuevaPromocion/NuevaPromocion';
import DetallePromocion from '../Promociones/DetallePromocion/DetallePromocion';
import PrefijoCampana from '../Promociones/PrefijoCampana/NuevoPrefijoCampana';
import {ExtraccionesCafe} from '../ExtraccionesCafe';
import {ReporteExtractos} from '../ReporteExtractos';
import Empresas from '../miCaffenioEmpresas/empresas';
import ProyeccionProductos from '../ProyeccionProductos/proyeccionProductos';
import NewProyeccion from '../ProyeccionProductos/NewProyeccion';
import { ProyeccionFormula } from '../ProyeccionFormula';
import { ReporteProyeccion } from '../ReporteProyeccion';
import ComunicadosVP from '../ComunicadosVP/ComunicadosVP';
import NewComunicado from '../ComunicadosVP/NewComunicado';
import { PagosBancos } from '../PagosBancos/pagosBancos';
import { CoordinadoresSupervisores } from '../coordinadoresSupervisores/CoordinadoresSupervisores';
import { NewCoordinadoresSupervisores } from '../coordinadoresSupervisores/NewCoordinadoresSupervisores';
import { MovimientosMenu } from '../movimientosMenu/MovimientosMenu';
import { HistorialVersion } from '../historialVersion/HistorialVersion';


/** 
 * Componente encargado de englobar toda la aplicación en el apartado del usuario ya en sesión
 * Esto conlleva el despliegue del Menú, Header y el componente correspondiente a la ruta actual
 */

const RoutesContainer = posed.div({
    enter: { opacity: 1, delay: 150 },
    exit: { opacity: 0 }
});

export default class AppContainer extends React.Component{
    
    render(){
        return (
            <Route render={({location}) => (
                <div className="App">
                    <div className="columns">
                        <Menu/>
                        <div className="column is-1"/>
                        <div className="column is-8 admin-panel">
                            <Header/>
                            <div className="padding-top">
                                <PoseGroup>
                                    <RoutesContainer key={location.pathname}>
                                        <Switch location={location}>
                                            {/* 
                                                Aquí se registran todas las rutas disponibles como hijos de este
                                                componente, así como el componente correspondiente a las mismas.
                                            */}
                                            <PrivateRoute path="/toppings" component={Toppings} roles={[roles.ADMIN,roles.MKT, roles.SUPERVISOR_VENTA_DRIVE, roles.LIDER_DRIVE, roles.ESPECIALISTA_DE_PRODUCTO, roles.COORD_OPER_DRIVE, roles.ADMINDRIVE]} />  
                                            {/* <PrivateRoute path="/recetas/editar/:id" component={NewRecipe} roles={[roles.ADMIN]} />  */}
                                            <PrivateRoute path="/recetas/nueva" component={NewRecipeMulti} roles={[roles.ADMIN, roles.ESPECIALISTA_DE_PRODUCTO, roles.COORDINADOR_GESTION_OPERACIONES]} /> 
                                            <PrivateRoute path="/recetas/editar/:id" component={NewRecipeMulti} roles={[roles.ADMIN, roles.ESPECIALISTA_DE_PRODUCTO, roles.COORDINADOR_GESTION_OPERACIONES,]} /> 
                                            <PrivateRoute path="/recetas" component={Recipes} roles={[roles.ADMIN, roles.SUPERVISOR_VENTA_DRIVE, roles.LIDER_DRIVE, roles.ESPECIALISTA_DE_PRODUCTO, roles.COORDINADOR_GESTION_OPERACIONES,]} />
                                            <PrivateRoute path="/recetas-imagenes" component={RecipesImage} roles={[roles.ADMIN, roles.SUPERVISOR_VENTA_DRIVE, roles.LIDER_DRIVE, roles.ESPECIALISTA_DE_PRODUCTO, roles.COORDINADOR_GESTION_OPERACIONES,]} />
                                            <PrivateRoute path="/clasificaciones/nueva" component={NewClassification} roles={[roles.ADMIN,roles.ESPECIALISTA_DE_PRODUCTO, roles.COORDINADOR_GESTION_OPERACIONES,]} />
                                            <PrivateRoute path="/clasificaciones" component={Classifications} roles={[roles.ADMIN, roles.ESPECIALISTA_DE_PRODUCTO, roles.COORDINADOR_GESTION_OPERACIONES, roles.SUPERVISOR_VENTA_DRIVE, roles.LIDER_DRIVE]} />
                                            <PrivateRoute path="/sabores/nuevo" component={NewFlavor} roles={[roles.ADMIN,roles.ESPECIALISTA_DE_PRODUCTO, roles.COORDINADOR_GESTION_OPERACIONES,]} />
                                            <PrivateRoute path="/sabores" component={Flavors} roles={[roles.ADMIN, roles.SUPERVISOR_VENTA_DRIVE, roles.LIDER_DRIVE, roles.ESPECIALISTA_DE_PRODUCTO, roles.COORDINADOR_GESTION_OPERACIONES,]} />
                                            <PrivateRoute path="/granos/nuevo" component={NewGrain} roles={[roles.ADMIN, roles.ESPECIALISTA_DE_PRODUCTO, roles.COORDINADOR_GESTION_OPERACIONES,]} />
                                            <PrivateRoute path="/granos" component={Grains} roles={[roles.ADMIN, roles.SUPERVISOR_VENTA_DRIVE, roles.LIDER_DRIVE, roles.ESPECIALISTA_DE_PRODUCTO, roles.COORDINADOR_GESTION_OPERACIONES,]} />
                                            <PrivateRoute path="/leches/nueva" component={NewMilk} roles={[roles.ADMIN, roles.ESPECIALISTA_DE_PRODUCTO, roles.COORDINADOR_GESTION_OPERACIONES,]} />
                                            <PrivateRoute path="/leches" component={Milks} roles={[roles.ADMIN, roles.SUPERVISOR_VENTA_DRIVE, roles.LIDER_DRIVE, roles.ESPECIALISTA_DE_PRODUCTO, roles.COORDINADOR_GESTION_OPERACIONES,]} />
                                            <PrivateRoute path="/presentaciones/nueva" component={NewPresentation} roles={[roles.ADMIN, roles.ESPECIALISTA_DE_PRODUCTO, roles.COORDINADOR_GESTION_OPERACIONES,]} />
                                            <PrivateRoute path="/presentaciones" component={Presentations} roles={[roles.ADMIN, roles.SUPERVISOR_VENTA_DRIVE, roles.LIDER_DRIVE,roles.ESPECIALISTA_DE_PRODUCTO, roles.COORDINADOR_GESTION_OPERACIONES,]} />
                                            <PrivateRoute path="/tipo-inventario/nuevo" component={NewInventoryType} roles={[roles.ADMIN,roles.COORDINADOR_GESTION_OPERACIONES, roles.ESPECIALISTA_DE_PRODUCTO,]} /> 
                                            <PrivateRoute path="/tipo-inventario/:id/editar" component={NewInventoryType} roles={[roles.ADMIN,roles.COORDINADOR_GESTION_OPERACIONES, roles.ESPECIALISTA_DE_PRODUCTO,]} />
                                            <PrivateRoute path="/tipo-inventario" component={InventoryType} roles={[roles.ADMIN, roles.ESPECIALISTA_DE_PRODUCTO,roles.COORDINADOR_GESTION_OPERACIONES, roles.SUPERVISOR_VENTA_DRIVE, roles.LIDER_DRIVE]} />
                                            <PrivateRoute path="/almacen" component={Warehouse} roles={[roles.ADMIN,roles.ESPECIALISTA_DE_PRODUCTO,roles.COORDINADOR_GESTION_OPERACIONES, roles.SUPERVISOR_VENTA_DRIVE, roles.LIDER_DRIVE]} />
                                            <PrivateRoute path="/items-inventario/:id/editar" component={NewInventoryItem} roles={[roles.ADMIN, roles.COORDINADOR_GESTION_OPERACIONES, roles.ESPECIALISTA_DE_PRODUCTO]} />
                                            <PrivateRoute path="/items-inventario/:id/:lista/editar" component={NewInventoryItem} roles={[roles.ADMIN, roles.COORDINADOR_GESTION_OPERACIONES, roles.ESPECIALISTA_DE_PRODUCTO]} />
                                            <PrivateRoute path="/items-inventario/nuevo" component={NewInventoryItem} roles={[roles.ADMIN, roles.COORDINADOR_GESTION_OPERACIONES, roles.ESPECIALISTA_DE_PRODUCTO]} />
                                            <PrivateRoute path="/items-inventario" component={InventoryItems} roles={[roles.ADMIN, roles.COORDINADOR_GESTION_OPERACIONES, roles.ESPECIALISTA_DE_PRODUCTO, roles.SUPERVISOR_VENTA_DRIVE, roles.LIDER_DRIVE]} />
                                            <PrivateRoute path="/tipos-receta/nuevo" component={NewRecipeType} roles={[roles.ADMIN, roles.ESPECIALISTA_DE_PRODUCTO, roles.COORDINADOR_GESTION_OPERACIONES]} /> 
                                            <PrivateRoute path="/tipos-receta" component={RecipeTypes} roles={[roles.ADMIN, roles.SUPERVISOR_VENTA_DRIVE, roles.LIDER_DRIVE, roles.ESPECIALISTA_DE_PRODUCTO, roles.COORDINADOR_GESTION_OPERACIONES]} />
                                            <PrivateRoute path="/unidades/nueva" component={NewUnit} roles={[roles.ADMIN,roles.COORDINADOR_GESTION_OPERACIONES, roles.ESPECIALISTA_DE_PRODUCTO]} /> 
                                            <PrivateRoute path="/unidades" component={Units} roles={[roles.ADMIN, roles.COORDINADOR_GESTION_OPERACIONES, roles.ESPECIALISTA_DE_PRODUCTO, roles.SUPERVISOR_VENTA_DRIVE, roles.LIDER_DRIVE]} />
                                            <PrivateRoute path="/saldo-regalo" component={SaldosRegalo} roles={[roles.ADMIN, roles.MKT, roles.CRM]}/>
                                            <PrivateRoute path="/remesas" component={Remesas} roles={[roles.ADMIN, roles.COORDINADOR_DISTRIBUCION, roles.SUPERVISOR_VENTA_DRIVE]} />
 
                                            <PrivateRoute path="/menus/:id/detalle" component={MenuDetail} roles={[roles.ADMIN,roles.MKT, roles.SUPERVISOR_VENTA_DRIVE, roles.LIDER_DRIVE, roles.ESTRATEGIA_DIGITAL, roles.ESPECIALISTA_DE_PRODUCTO, roles.COORD_OPER_DRIVE, roles.MKT_OS]} /> 
                                            <PrivateRoute path="/menus/nuevo" component={NewMenu} roles={[roles.ADMIN,roles.MKT, roles.SUPERVISOR_VENTA_DRIVE, roles.LIDER_DRIVE, roles.ESTRATEGIA_DIGITAL, roles.ESPECIALISTA_DE_PRODUCTO, roles.COORD_OPER_DRIVE, roles.MKT_OS]} /> 
                                            <PrivateRoute path="/menus" component={MenuDrives} roles={[roles.ADMIN,roles.MKT, roles.SUPERVISOR_VENTA_DRIVE, roles.LIDER_DRIVE, roles.ESTRATEGIA_DIGITAL, roles.ESPECIALISTA_DE_PRODUCTO, roles.COORD_OPER_DRIVE, roles.MKT_OS]} />

                                            <PrivateRoute path="/lista-precios/:id/detalle" component={PriceDetail} roles={[roles.ADMIN, roles.MKT, roles.SUPERVISOR_VENTA_DRIVE, roles.LIDER_DRIVE, roles.ESPECIALISTA_DE_PRODUCTO, roles.MKT_OS]} />
                                            <PrivateRoute path="/lista-precios/nuevo" component={NewPriceList} roles={[roles.ADMIN, roles.MKT, roles.SUPERVISOR_VENTA_DRIVE, roles.LIDER_DRIVE, roles.ESPECIALISTA_DE_PRODUCTO, roles.MKT_OS]} />
                                            <PrivateRoute path="/lista-precios" component={PricesDrive} roles={[roles.ADMIN, roles.MKT, roles.SUPERVISOR_VENTA_DRIVE, roles.LIDER_DRIVE, roles.ESPECIALISTA_DE_PRODUCTO, roles.MKT_OS]} />

                                            <PrivateRoute path="/caffenio-drive" component={CaffenioDrive} roles={[roles.ADMIN, roles.SUPERVISOR_VENTA_DRIVE, roles.LIDER_DRIVE, roles.ESPECIALISTA_DE_PRODUCTO, roles.CONFIGURACION_VP,roles.ADMINDRIVE]} />
                                            <PrivateRoute path="/drive/nuevo" component={NewCaffenio} roles={[roles.ADMIN, roles.ESPECIALISTA_DE_PRODUCTO, roles.CONFIGURACION_VP,roles.ADMINDRIVE]} />
 
                                            <PrivateRoute path="/productos-proyeccion" component={ProyeccionProductos} roles={[roles.ADMIN, roles.SUPERVISOR_VENTA_DRIVE, roles.LIDER_DRIVE,
                                                                                                                roles.ENCARGADO_DE_TURNO, roles.ADMINDRIVE                                                            
                                            ]} />
                                            <PrivateRoute path="/proyeccion/nuevo" component={NewProyeccion} roles={[roles.ADMIN, roles.SUPERVISOR_VENTA_DRIVE, roles.LIDER_DRIVE,
                                                                                                                roles.ENCARGADO_DE_TURNO, roles.ADMINDRIVE                                                            
                                            ]} />
                                            <PrivateRoute path="/proyeccion/formula" component={ProyeccionFormula} roles={[roles.ADMIN, roles.ADMINDRIVE ]} />
                                            <PrivateRoute path="/reporte-proyeccion" component={ReporteProyeccion} roles={[roles.ADMIN, roles.ADMINDRIVE ]} />
                                            <PrivateRoute path="/comunicados-vp" component={ComunicadosVP} roles={[roles.ADMIN, roles.ADMINDRIVE ]} />
                                            <PrivateRoute path="/comunicado/nuevo" component={NewComunicado} roles={[roles.ADMIN, roles.ADMINDRIVE ]} />
                                            {/* <PrivateRoute path="/extractos-cafe" component={ExtraccionesCafe} roles={[roles.ADMIN, roles.SUPERVISOR_VENTA_DRIVE, roles.LIDER_DRIVE,
                                                                                                                roles.ENCARGADO_DE_TURNO                                                            
                                            ]} />
                                            <PrivateRoute path="/reporte-extractos" component={ReporteExtractos} roles={[roles.ADMIN, roles.SUPERVISOR_VENTA_DRIVE, roles.LIDER_DRIVE,
                                                                                                                roles.ENCARGADO_DE_TURNO                                                            
                                            ]} /> */}

                                            <PrivateRoute path= "/featured" component = {newFeatured} roles={[roles.ADMIN, roles.MKT, roles.SUPERVISOR_VENTA_DRIVE, roles.LIDER_DRIVE, roles.ESPECIALISTA_DE_PRODUCTO]} />   

                                           {/* Sección de aplicación Móvil */}
                                           <PrivateRoute path="/app/segmentaciones/editar/:id" component={NuevaSegmentacion} roles={[roles.ADMIN, roles.ADMIN_APP, roles.CRM]} />
                                            <PrivateRoute path="/app/segmentaciones/nueva" component={NuevaSegmentacion} roles={[roles.ADMIN, roles.ADMIN_APP, roles.CRM]} />
                                            <PrivateRoute path="/app/segmentaciones/nuevaSeg" component={NuevaSegmentacionUsuario} roles={[roles.ADMIN, roles.ADMIN_APP, roles.CRM]} />
                                            <PrivateRoute path="/app/segmentaciones" component={Segmentaciones} roles={[roles.ADMIN, roles.ADMIN_APP, roles.CRM]} />
                                            <PrivateRoute path="/app/notificaciones/editar/:id" component={NuevaNotificacion} roles={[roles.ADMIN, roles.ADMIN_APP, roles.CRM, roles.MKT_OS]} />
                                            <PrivateRoute path="/app/notificaciones/:id/detalle" component={DetalleNotificacion} roles={[roles.ADMIN, roles.ADMIN_APP, roles.CRM, roles.MKT_OS]} />
                                            <PrivateRoute path="/app/notificaciones/agenda" component={Agenda} roles={[roles.ADMIN, roles.ADMIN_APP, roles.CRM, roles.MKT_OS]} />
                                            <PrivateRoute path="/app/notificaciones/nueva" component={NuevaNotificacion} roles={[roles.ADMIN, roles.ADMIN_APP, roles.CRM, roles.MKT_OS]} />
                                            <PrivateRoute path="/app/notificaciones" component={Notificaciones} roles={[roles.ADMIN, roles.ADMIN_APP, roles.CRM, roles.MKT_OS]} />
                                            <PrivateRoute path="/app/menu/ordenar" component={OrdenarMenu} roles={[roles.ADMIN, roles.ADMIN_APP, roles.ESTRATEGIA_DIGITAL, roles.MKT_OS]} />
                                            <PrivateRoute path="/app/menu/clasificacion/:id/editar" component={EditarClasificacionApp} roles={[roles.ADMIN, roles.ADMIN_APP, roles.ESTRATEGIA_DIGITAL, roles.MKT_OS]} />
                                            <PrivateRoute path="/app/menu/orden" component={OrdenMenuApp} roles={[roles.ADMIN, roles.ADMIN_APP, roles.ESTRATEGIA_DIGITAL, roles.MKT_OS]} />

                                            {/* Sección de uber eats */}
                                            <PrivateRoute path="/restaurantes/:id" component={RestaurantDetails} roles={[roles.ADMIN, roles.ADMIN_UBER_EATS]} />
                                            <PrivateRoute path="/restaurantes" component={Restaurantes} roles={[roles.ADMIN, roles.ADMIN_UBER_EATS]} />
                                            <PrivateRoute path="/ubereats/orden" component={InfoOrderRestaurant} roles={[roles.ADMIN, roles.ADMIN_UBER_EATS]} />
                                            
                                            {/* Sección de administración */}
                                            <PrivateRoute exact path="/promos" component={PromosDrive} roles={[roles.ADMIN, roles.MKT_OS, roles.MKT_REGIONAL]} />
                                            <PrivateRoute path="/promos/nueva-promo" component={ NuevaPromo } roles={[roles.ADMIN, roles.MKT_OS, roles.MKT_REGIONAL]} />
                                            <PrivateRoute path="/promos/editar-promo" component={ NuevaPromo } roles={[roles.ADMIN, roles.MKT_OS, roles.MKT_REGIONAL]} />
                                            <PrivateRoute path="/coordinadores-supervisores" component={CoordinadoresSupervisores} roles={[roles.ADMIN, roles.ADMINDRIVE]} />
                                            <PrivateRoute path="/coordinadores/nuevo" component={NewCoordinadoresSupervisores} roles={[roles.ADMIN, roles.ADMINDRIVE]} />
                                            <PrivateRoute path="/asignacion" component={AsignacionLiderSupervisor} roles={[roles.ADMIN, roles.ESPECIALISTA_DE_GESTION]} />
                                            <PrivateRoute path="/historial" component={HistorialMovimientos} roles={[roles.ADMIN, roles.ESPECIALISTA_DE_PRODUCTO]} />
                                            <PrivateRoute path="/catalogo-sucursal" component={CatalogoSucursales} roles={[roles.ADMIN]} />
                                            <PrivateRoute path="/catalogo-plaza" component={CatalogoPlazas} roles={[roles.ADMIN]} />
                                            <PrivateRoute path="/catalogo-impuesto" component={CatalogoImpuestos} roles={[roles.ADMIN]} />
                                            <PrivateRoute path="/admin-usuarios" component={AdminUsuarios} roles={[roles.ADMIN]} />
                                            <PrivateRoute path="/pagos-bancos" component={PagosBancos} roles={[roles.ADMIN, roles.REPORTES]} />

                                            {/* Reportes */}
                                            <PrivateRoute path="/movimientos-menu" component={MovimientosMenu} roles={[roles.ADMIN, roles.ESPECIALISTA_DE_PRODUCTO, roles.SOPORTE]} />
                                            <PrivateRoute path="/historial-version" component={HistorialVersion} roles={[roles.ADMIN, roles.SOPORTE]} />
                                            <PrivateRoute path="/depositos-bancarios" component={CaffenioReporteTabla} roles={[roles.ADMIN, roles.REPORTES]} />
                                            <PrivateRoute path="/reportes-conciliaciones" component={ReporteConciliacion} roles={[roles.ADMIN, roles.REPORTES]} />
                                            <PrivateRoute path="/reportes-arqueos" component={ReporteArqueo} roles={[roles.ADMIN, roles.REPORTES]} />
                                            <PrivateRoute path="/reportes-retiros" component={ReporteRetiros} roles={[roles.ADMIN, roles.REPORTES]} />
                                            <PrivateRoute path="/reportes-cancelaciones" component={ReporteCancelaciones} roles={[roles.ADMIN, roles.REPORTES]} />

                                            {/* Sección de promociones*/}
                                            <PrivateRoute path="/app/promociones/editar/:id" component={NuevaPromocion} roles={[roles.ADMIN, roles.ADMIN_APP, roles.CRM, roles.MKT_OS]} />
                                            <PrivateRoute path="/app/promociones/detalle/:id" component={DetallePromocion} roles={[roles.ADMIN, roles.ADMIN_APP, roles.CRM, roles.MKT_OS]} />
                                            <PrivateRoute path="/app/promociones/nueva" component={NuevaPromocion} roles={[roles.ADMIN, roles.ADMIN_APP, roles.CRM, roles.MKT_OS]} />
                                            <PrivateRoute path="/app/promociones/prefijo/:id" component={PrefijoCampana} roles={[roles.ADMIN, roles.ADMIN_APP, roles.CRM, roles.MKT_OS]} />
                                            <PrivateRoute path="/app/promociones" component={Promociones} roles={[roles.ADMIN, roles.ADMIN_APP, roles.CRM, roles.MKT_OS]} />
                                            
                                            <PrivateRoute path="/drive-reportes" component={CaffenioReporteTabla} roles={[roles.ADMIN, roles.REPORTES]} />

                                            {/* Sección Mi CAFFENIO empresas */}
                                            <PrivateRoute path="/micaffenio/empresas" component={Empresas} roles={[roles.ADMIN, roles.MICAFFENIO_EMPRESAS]} />

                                            {/* Componente anónimo sin estado para un logout y redirigir al inicio */}
                                            <Route path="/logout" component={() => {
                                                localStorage.clear();

                                                window.location.reload(); 
                                                return <Redirect to={{ pathname: '/', state: null}} />;
                                            }}/>
                                            
                                            <Route path="/" component={Index}/>  

                                        </Switch>
                                    </RoutesContainer>
                                </PoseGroup>
                            </div>
                        </div>
                    </div>
                    <ToastContainer position="bottom-right"/>
                </div>
            )}
            />    

        )
    }
}